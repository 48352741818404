<template>

  <div class="parent-modal">
    <div class="modal myModal fade" id="edit-category" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header align-items-center">
            <h3 class="modal-title">Edit Category</h3>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
            </button>
          </div>
          <div class="modal-body category-body">
            <form @submit.prevent="onSubmit" class="modal-form">
              <label>Name</label><br>
              <input v-model="currentCategory.name" class="form-input cashback-input mb-3" placeholder="Enter a name">

              <div class="modal-img" style="margin: 15px 0 20px 0">
                <div class="d-flex">
                  
                  <div class="d-flex" v-if="currentCategory.imgArray && currentCategory.imgArray.length > 0">
                    <div v-for="(img, index) in imagePreview" :key="index" class="selected-images">
                      <img id="choosed-img" :src="img" class="show-images mr-2" />
                      <div class="selected-overlay1">
                        <img @click="removeImage(index)" class="remove-image" src="../../../assets/icons/deleteClient.svg">
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <label>
                      <div class="upload-button" >+ Upload photo</div>
                      <!-- <img src="../../../assets/img/modal-img.svg "> -->
                      <input @change="onFileChange($event)" class="d-none" ref="uploadPhoto" accept="image/*" multiple id="imgArray" type="file" name="imgArray">
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <label>Move to the Category</label>
                <select v-model="currentCategory.parent" class="form-control long-form-control modal-select mb-5">
                  <option value="">Without category</option>
                  <option v-for="category in listCategory" :value="category._id" :key="category._id">{{ category.name }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-end">
                <button @click.prevent="onSubmit(currentCategory._id)" class="save">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "EditCategory",
  props: ['select_category', 'getCategories', 'listCategory'],
  data() {
    return {
      currentCategory: {
        name: '',
        parent: '',
        type: 'product',
        imgArray: []
      },
    };
  },
  computed: {
    imagePreview() {
      if (this.currentCategory.imgArray && this.currentCategory.imgArray.length > 0) {
        return this.currentCategory.imgArray.map(img => {
          if (typeof img === 'string') {
            // Если img - это строка (путь к файлу), возвращаем полный URL
            return `${this.$server}/${img}`;
          } else if (img instanceof File) {
            // Если img - это объект File, создаем URL
            return URL.createObjectURL(img);
          }
          return img;
        });
      }
      return [];
    }
  },
  methods: {

    onFileChange(event) {
      var valid = ["image/png", "image/jpg", "image/jpeg", "image/svg"];
      const files = event.target.files;

      if (files.length > 0) {
        const file = files[0];
        if (this.currentCategory.imgArray.length >= 1) {
          this.$warningAlert("Maximum amount of images is 1");
          return;
        }
        
        if (file.size > 3000000) {
          this.$warningAlert('Image size exceeds 3 MB');
          return;
        }
        
        if (!valid.includes(file.type)) {
          this.$warningAlert('Image type not png or jpg');
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          this.currentCategory.imgArray.push(file);
        };
        reader.readAsDataURL(file);
      }
    },

    removeImage(index) {
      this.currentCategory.imgArray.splice(index, 1);
    },

    onSubmit(id) {
      const data = new FormData();
      data.append('name', this.currentCategory.name)
      data.append('type', this.currentCategory.type)
      data.append('parent', this.currentCategory.parent)
      for(let item in this.currentCategory.imgArray){
        if(item<1){
          data.append('img', this.currentCategory.imgArray[item])
        }
      }

      this.axios.put(this.url('updateCategory', id), data)
        .then(() => {
          this.getCategories()
          this.$informationAlert('Category changed')
          $('#edit-category').modal("hide")
        })
    }
  },
  watch: {
    select_category(newCat) {
      this.currentCategory.name = newCat.name || '';
      this.currentCategory.parent = newCat.parent || '';
      this.currentCategory.type = newCat.type || 'product';
      this.currentCategory._id = newCat._id || '0';
      this.currentCategory.imgArray = newCat.img ? [newCat.img] : [];
      console.log("this.currentCategory!!!", this.currentCategory);
    }
  },
}
</script>

<style scoped>
.selected-images:hover .remove-image{
  opacity: 1;
}
.show-images{
  border-radius: 50%;
  object-fit: contain;
}
.remove-image{
  opacity:0;
  transition:.3s;
}

.upload-button {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #a0a0ff;
  border-radius: 5px;
  background-color: transparent;
  color: #a0a0ff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.upload-button:hover {
  background-color: #f0f0ff;
}

.upload-button:active {
  background-color: #e0e0ff;
}

.selected-overlay1 {
  width: 69px;
  height: 69px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cashback-input {
  width: 100%;
}
</style>