<template>
  <div class="news">
    <NewsNavbar/>
      <router-view>
  
      </router-view>
  </div>
  </template>
  
  <script>
  import NewsNavbar from "@/components/News/NewsNavbar";
  export default {
  name: "News",
    components:{
      NewsNavbar
    }
  
  
  
  }
  </script>
  
  <style scoped>
  
  .news{
    padding:0 15px;
  
  }
  </style>