<template>
  <div class="parent-modal">
  <div class="modal myModal fade" id="add-category" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content category-content">
        <div class="modal-header category-header align-items-center">
          <h3 class="modal-title">Add Category</h3>
          <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
          </button>
        </div>
        <div class="modal-body category-body">
          <form @submit.prevent="onSubmit" class="modal-form">
            <label>Name</label>
            <input class="form-input cashback-input mb-3" v-model="new_category.name" name="name" placeholder="Enter a name">

            <!-- <div>
              <label>Select category</label>
              <select v-model="new_category.parent" class="form-control long-form-control modal-select mb-5">
                <option v-for="cat in listCategory" :key="cat.id"  :value="cat._id">{{cat.name}}</option>
              </select>
            </div> -->

            <div class="modal-img" style="margin: 15px 0 20px 0">
            <div class="d-flex">
              
              <div class="d-flex" v-if="new_category.imgArray.length !==0">
                <div  v-for="(img,index) in imagePreview" :key="index" class="selected-images">
                  <img id="choosed-img" :src="img" class="show-images mr-2" />
                  <div class="selected-overlay1">
                    <img @click="removeImage(index)" class="remove-image" src="../../../assets/icons/deleteClient.svg">
                  </div>
                </div>
              </div>
              <div v-else>
                <label>
                  <div class="upload-button" >+ Upload photo</div>
                  <!-- <img src="../../../assets/img/modal-img.svg "> -->
                  <input @change="onFileChange($event)" class="d-none" ref="uploadPhoto" accept="image/*" multiple id="imgArray" type="file" name="imgArray">
                </label>
              </div>
            </div>
            </div>

            <div>
              <label>Move to the category</label><br>
              <div class="sel-block-main">
                <div class="sel-block-main_modal" :class="{ 'show-category': isCategoryVisible }">
                  <div v-for="(category, index) in navigateDisplayParentCategories" :key="category._id">
                    <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'menu' + index"
                      :class="{ active: selectedCategory === category._id }">
                      <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category" @click="selectCategory(category._id, category.name)">{{ category.name }} </span>
                        <span v-if="category.children && category.children.length > 0" class="arrow" :class="{ 'arrow-down': isCategoryOpen[index] }"  @click="toggleCategory(index)">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_89_1499)">
                            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_89_1499">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </p>
                    </li>
                    <div v-show="isCategoryOpen[index]">
                      <div v-for="(child) in category.children" :key="child._id">
                        <li :key="child._id" class="catalog-list catalog-list-child"
                          :id="child.name" :ref="'menu' + index" :class="{ active: selectedCategory === child._id }">
                          <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                            <span class="name-category" @click="selectCategory(child._id, child.name)">{{ child.name }} </span>
                            <span v-if="child.children && child.children.length > 0" class="arrow" :class="{ 'arrow-down': isChildOpen[child._id] }" @click.stop="toggleChild(index, child._id)">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_89_1499)">
                                <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_89_1499">
                                <rect width="18" height="18" fill="white"/>
                                </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </p>
                        </li>
                        <div v-show="isChildOpen[child._id]">
                          <li v-for="(nestedChild) in child.children" :key="nestedChild._id" class="catalog-list catalog-list-child catalog-list-child-nested"
                            :id="nestedChild.name" :ref="'menu' + index" :class="{ active: selectedCategory === nestedChild._id }">
                            <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                              <span class="name-category" @click="selectCategory(nestedChild._id, nestedChild.name)">{{ nestedChild.name }}</span>
                            </p>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input placeholder="Without category" type="text" class="cashback-input" v-model="selectedCategoryInput" readonly @click="toggleCategoryVisibility">
              </div>
            </div>
            <br>
            <div class="d-flex justify-content-end">
              <button  class="save">Save</button>
            </div>
          </form>

        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>

import $ from 'jquery'
export default {
name: "AddCategory",
  props:{
    listCategory:{
      type:Array,
      required:true
    },
    getCategories:{
      type:Function,
    },
    navigateDisplayParentCategories:{
      type:Array,
      required:true
    },
  },
  data(){
    return{
      new_category: {
        name: '',
        type: 'product',
        parent: '',
        imgArray: []
      },

      currentData: {},
      isCategoryOpen: {},
      isChildOpen: {},
      selectedCategory: null,
      isCategoryVisible: false,
      selectedCategoryInput:'',
    }
  },
  computed:{
    imagePreview(){
      return this.new_category.imgArray.map((item)=>{
        return URL.createObjectURL(item)
      })
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    
    // Добавляем слушатель события для сброса формы при закрытии модального окна
    $('#add-category').on('hidden.bs.modal', this.resetForm);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    
    // Удаляем слушатель события при уничтожении компонента
    $('#add-category').off('hidden.bs.modal', this.resetForm);
  },

  methods:{
    toggleCategoryVisibility() {
      this.isCategoryVisible = !this.isCategoryVisible;
    },
    closeCategory(event) {
      if (!document.querySelector('.sel-block-main').contains(event.target)) {
        // Если нет, закрываем категорию
        this.isCategoryVisible = false;
      }
    },
    selectCategory(categoryId, categoryName) {
      this.currentData.category = categoryId;
      this.selectedCategoryInput = categoryName;
      this.new_category.parent = categoryId;
    },
    toggleCategory(index) {
      console.log(index);
      this.isCategoryOpen = {...this.isCategoryOpen, [index]: !this.isCategoryOpen[index]};
    },
    toggleChild(index, childId) {
      this.isChildOpen = {...this.isChildOpen, [childId]: !this.isChildOpen[childId]};
    },
    toggleSubcategories(category) {
      // Переключаем флаг для показа/скрытия подкатегорий
      if (category.children) {
        category.showChildren = !category.showChildren;
      }
    },

    onFileChange() {
      var valid = ["image/png", "image/jpg", "image/jpeg", "image/svg"];
      $.each($("#imgArray")[0].files, (i,file)=> {
        console.log(i)
      if(this.new_category.imgArray.length<1){
         if(file && file.size > 3000000){
            this.$warningAlert('Image size exceeds 3 mb ')
         }
         else if(file && !valid.includes(file.type)){
            this.$warningAlert('Image type not png or jpg')
         }
        else{
           this.new_category.imgArray.push(file)
         }
      }
      else{
         this.$warningAlert("Maximum amount of images is 1")
        }
      })
    },

    removeImage(idx){
      this.new_category.imgArray = this.new_category.imgArray.filter((item,index)=>{
        console.log(item);
        return index !== idx
      })
        console.log(this.new_category.imgArray)
    },

    onSubmit(){
      const data = new FormData();
      data.append('name', this.new_category.name)
      data.append('type', this.new_category.type)
      data.append('parent', this.new_category.parent)
      for(let item in this.new_category.imgArray){
        if(item<1){
          data.append('img', this.new_category.imgArray[item])
        }
      }

      this.axios.post(this.url('addCategory'),data)
            .then(()=>{
              this.$successAlert('Category has been added')
              this.getCategories()
            })
            .catch((error)=>{
              this.$warningAlert('Failed to add category')

              console.log("Error"+error)
            })

      $('#add-category').modal("hide")
      this.resetForm();
    },

    resetForm() {
      this.new_category = {
        name: '',
        type: 'product',
        parent: '',
        imgArray: []
      };
      this.selectedCategoryInput = '';
      this.selectedCategory = null;
    },

    handleOutsideClick(event) {
      if (!this.$el.querySelector('.sel-block-main').contains(event.target)) {
        this.isCategoryVisible = false;
      }
    },
  }
}
</script>

<style scoped>
.modal-select{
  font-size: 14px;
}
.category-content{
  border:0;
  border-radius: 0;
  width: 562px;
}
.parent-modal{
  display: flex;
}
.category-body{
  padding: 0 50px;
  padding-bottom: 34px;
}
.category-body input{
  width: 100%;
}
.category-header{
  padding:34px 50px;
}

.selected-images:hover .remove-image{
  opacity: 1;
}
.show-images{
  border-radius: 50%;
  object-fit: contain;
}
.remove-image{
  opacity:0;
  transition:.3s;
}

.sel-block-main{
  position: relative;
}
.sel-block-main input{
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal{
  position: absolute;
  top: 100%;
  background: white;
  z-index: 1;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 2px 11px 35px 0px rgba(0, 0, 0, 0.1);
  display: none;
  min-width: 120px;
  padding-bottom: 20px;
  max-height: 80vh;
  overflow-y: auto;
}
.sel-block-main .sel-block-main_modal.show-category{
  display: block;
}
.sel-block-main .sel-block-main_modal div .catalog-list{
  list-style: none;
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal div .catalog-list:hover{
  background: rgb(248, 249, 255);
  color: black;
}
.sel-block-main .sel-block-main_modal .catalog-list + div {
    margin-left: 16px;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 0 16px;
  position: relative;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text::after{
    content: '';
    position: absolute;
    display: inline-block;
    height: 1px;
    background: rgb(211, 211, 211);
    left: auto;
    bottom: 0;
    width: calc(100% - 32px);
    right: auto;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow, .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow path{
  transition: 0.3s ease;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow:hover path{
  fill: black;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow.arrow-down{
  transform: rotate(180deg);
}
.catalog-list .category-text .name-category{
  width: 100%;
  padding: 15px 0px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-button {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #a0a0ff;
  border-radius: 5px;
  background-color: transparent;
  color: #a0a0ff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.upload-button:hover {
  background-color: #f0f0ff;
}

.upload-button:active {
  background-color: #e0e0ff;
}

.selected-overlay1 {
  width: 69px;
  height: 69px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>